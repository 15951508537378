<vex-secondary-toolbar current="i18n:PAGES.NEWSTUDENT">
	<vex-breadcrumbs [crumbs]="['i18n:PAGES.DASHBOARD', 'i18n:PAGES.NEWSTUDENT']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<vex-page-layout mode="card">
	<vex-page-layout-header class="h-6"></vex-page-layout-header>
	<vex-page-layout-content class="container">
		<div class="card overflow-auto">
			<div class="p-4">
				<form [formGroup]="form" (ngSubmit)="onSubmit(model)">
					@if (loading) {
						<div role="status" class="space-y-2.5 animate-pulse w-full">
							<div class="flex items-center w-full">
								<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
							</div>
							<div class="flex items-center w-full max-w-[480px]">
								<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
							</div>
							<div class="flex items-center w-full max-w-[400px]">
								<div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
							</div>
							<div class="flex items-center w-full max-w-[480px]">
								<div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
							</div>
							<div class="flex items-center w-full max-w-[440px]">
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
								<div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
							</div>
							<div class="flex items-center w-full max-w-[360px]">
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
								<div class="h-2.5 ms-2 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
								<div class="h-2.5 ms-2 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
							</div>
							<span class="sr-only">Loading...</span>
						</div>
					}
					@if (fields) {
						<formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
						<div class="flex justify-end gap-4">
							@if (submitLoading) {
								<mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
							}
							<button mat-raised-button class="mt-4" type="submit" color="primary">
								{{ 'PAGE.NEW_STUDENT.SUBMIT' | translate }}
							</button>
						</div>
					}
				</form>
			</div>
		</div>
	</vex-page-layout-content>
</vex-page-layout>
