import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { UtclubFormlyFieldConfig } from 'src/app/core/components/formly/utclub-formly-field.interface';
import { LookupService } from 'src/app/core/services/lookup.service';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';
import { EntityService } from 'src/app/core/services/entity.service';
import { JsonPipe } from '@angular/common';
import { ApiService } from 'src/app/core/services/api.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Response } from 'src/app/core/models/response.interface';
import { Enums } from 'src/app/core/models/enums/enum';

export interface UserPartner {
	Id: number;
	UserId: number;
	PartnerType: number;
	PartnerId: number;
}

@Component({
	selector: 'vex-new-student',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,

		FormlyModule,

		VexSecondaryToolbarComponent,
		VexBreadcrumbsComponent,
		VexPageLayoutComponent,
		VexPageLayoutHeaderDirective,
		VexPageLayoutContentDirective,

		JsonPipe
	],
	templateUrl: './new-student.component.html',
	styleUrl: './new-student.component.scss'
})
export class NewStudentComponent implements AfterViewInit {
	form = new FormGroup({});
	model: any = {};
	fields?: UtclubFormlyFieldConfig[];

	partners: { id: number; partner: UserPartner }[] = [];

	loading = false;
	submitLoading = false;
	constructor(
		private lookup: LookupService,
		private authService: AuthenticatorService,
		private entityService: EntityService,
		private cdr: ChangeDetectorRef,
		private snackBar: MatSnackBar,
		private apiService: ApiService
	) {}

	async ngAfterViewInit() {
		this.loading = true;
		this.cdr.detectChanges();

		var fields: UtclubFormlyFieldConfig[] = [
			{
				fieldGroupClassName: 'p-0 grid grid-cols-2 gap-4 mb-4',
				fieldGroup: [
					{
						key: 'firstName',
						type: 'input',
						props: {
							label: 'Ad',
							required: true
						}
					},
					{
						key: 'lastName',
						type: 'input',
						props: {
							label: 'Soyad',
							required: true
						}
					}
				]
			},
			{
				fieldGroupClassName: 'p-0 grid grid-cols-2 gap-4 mb-4',
				fieldGroup: [
					{
						key: 'cityId',
						type: 'cached-select',
						props: {
							label: 'Sehir',
							required: true,
							observable: this.lookup.turkeyCities$
						}
					},
					{
						key: 'gender',
						type: 'select',
						props: {
							label: 'Cinsiyet',
							required: true,
							options: [
								{ value: 1, label: 'Erkek' },
								{ value: 2, label: 'Kadin' }
							]
						}
					},

					{
						key: 'email',
						type: 'input',
						props: {
							label: 'Eposta',
							required: true
						}
					},
					{
						key: 'phoneNumber',
						type: 'input',
						props: {
							label: 'Telefon Numarasi',
							required: true,
							minLength: 10,
							maxLength: 12
						}
					},

					{
						key: 'educationLevel',
						type: 'enum-select',
						props: {
							label: 'Egitim Seviyesi',
							required: true,
							enum: Enums.EducationLevel
						}
					},
					{
						key: 'programType',
						type: 'enum-select',
						props: {
							label: 'Form Tipi',
							required: true,
							enum: Enums.ProgramType
						}
					},

					{
						key: 'preferredCountries',
						type: 'cached-select',
						props: {
							label: 'Tercih Edilen Ulkeler',
							multiple: true,
							observable: this.lookup.countries$
						}
					}
				]
			},

			{
				key: 'comment',
				type: 'textarea',
				props: {
					label: 'Yorum',
					rows: 3,
					required: true
				}
			}
		];

		var partners = this.authService.getClaimValue<UserPartner[]>('Partners');

		for (let index = 0; index < partners.length; index++) {
			const element = partners[index];
			this.partners.push({ id: index + 1, partner: element });
		}

		if (this.partners.length == 1) {
			this.model = {
				partnerId: this.partners[0].id
			};
		} else {
			var partnerOptions: { value: number; label: string }[] = [];
			for (let index = 0; index < this.partners.length; index++) {
				const element = this.partners[index];
				var label: string = '';
				switch (element.partner.PartnerType) {
					case 1:
						label = await this.entityService.getEntityLabel('StudyAbroadRepresentative', element.partner.PartnerId);
						break;
					case 2:
						label = await this.entityService.getEntityLabel('StudyAbroadPoint', element.partner.PartnerId);
						break;
					case 3:
						label = await this.entityService.getEntityLabel('School', element.partner.PartnerId);
						break;
					case 4:
						label = await this.entityService.getEntityLabel('CorporateInquiry', element.partner.PartnerId);
						break;
					case 5:
						label = await this.entityService.getEntityLabel('WebPortal', element.partner.PartnerId);
						break;
				}
				partnerOptions.push({ value: element.id, label: label });
			}

			fields.unshift({
				fieldGroupClassName: 'p-0 grid grid-cols-1 gap-4 mb-4',
				fieldGroup: [
					{
						key: 'partnerId',
						type: 'select',
						props: {
							label: 'Öğrenci Kaynağı',
							required: true,
							options: partnerOptions
						}
					}
				]
			});
		}

		this.fields = fields;
		this.loading = false;
		this.cdr.detectChanges();
	}

	onSubmit(model: any) {
		var partnerId = model.partnerId;
		var partner = this.partners.find((x) => x.id == partnerId)?.partner;
		if (!partner) {
			this.snackBar.open('Öğrenci kaynağı seçilmedi.', 'Tamam', {
				duration: 3000
			});
			return;
		}
		delete model.partnerId;
		model.studentSource = this.getStudentSourceFromPartnerType(partner.PartnerType);
		model.referrerId = partner.PartnerId;
		this.submitLoading = true;
		this.cdr.detectChanges();
		this.apiService.post<Response<number>>('partner/student-service/inquiry', model).subscribe({
			next: (response) => {
				this.submitLoading = false;
				if (response.data) {
					this.snackBar.open('Öğrenci başvurusu başarıyla alındı. Öğrenci numarası: ' + response.data, 'Tamam', {
						duration: 5000
					});
				}
				this.model = {};
				this.form.reset();
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.submitLoading = false;
				var errorMessage = error.error?.message || 'Bir hata oluştu.';
				this.snackBar.open(errorMessage, 'Tamam', {
					duration: 3000
				});
			}
		});
	}

	getStudentSourceFromPartnerType(partnerType: number): number {
		var studentSource = 0;
		switch (partnerType) {
			case 1:
				studentSource = 11;
				break;
			case 2:
				studentSource = 3;
				break;
			case 3:
				studentSource = 7;
				break;
			case 4:
				studentSource = 10;
				break;
			case 5:
				studentSource = 9;
				break;
		}
		return studentSource;
	}
}
