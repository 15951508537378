<vex-secondary-toolbar current="i18n:PAGES.DASHBOARD">
	<vex-breadcrumbs [crumbs]="['i18n:PAGES.DASHBOARD']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<vex-page-layout mode="card">
	<vex-page-layout-header class="h-6"></vex-page-layout-header>
	<vex-page-layout-content class="container">
		@if (loading) {
			<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
		}
		@if (result) {
			<div class="grid grid-cols-1 mb-4">
				<div class="card">
					<div class="grid grid-cols-3 justify-between gap-4">
						<div class="col-span-1 p-4">
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.INQUIRY' | translate }} </span>
								<span class="info-value"> {{ result.inquiry }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.INQUIRY_ACTIVE' | translate }} </span>
								<span class="info-value"> {{ result.inquiryActive }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.INQUIRY_CANCEL' | translate }} </span>
								<span class="info-value"> {{ result.inquiryCancel }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT' | translate }} </span>
								<span class="info-value"> {{ result.enrollment }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT_APPLICATION' | translate }} </span>
								<span class="info-value"> {{ result.enrollmentApplication }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT_TRAVEL' | translate }} </span>
								<span class="info-value"> {{ result.enrollmentTravel }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT_PROGRAM' | translate }} </span>
								<span class="info-value"> {{ result.enrollmentProgram }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT_CANCEL' | translate }} </span>
								<span class="info-value"> {{ result.enrollmentCancel }} </span>
							</div>
							<div class="info-line">
								<span class="info-title"> {{ 'PAGE.DASHBOARD.ENROLLMENT_COMPLETED' | translate }} </span>
								<span class="info-value"> {{ result.enrollmentCompleted }} </span>
							</div>
						</div>

						<div class="col-span-2">
							@if (series && labels) {
								<dashboard-chart [labels]="labels" [series]="series"></dashboard-chart>
							}
						</div>
					</div>
				</div>
			</div>

			@if (result && result.partnerPackageType) {
				<dashboard-premium-table
					[enrollmentDeserved]="result.enrollmentDeserved"
					[partnerPackageType]="result.partnerPackageType"
					[deservedPercentage]="deservedPercentage"
				></dashboard-premium-table>
			}

			<div class="mb-4 px-4 flex items-center gap-4">
				<mat-form-field subscriptSizing="dynamic" class="w-40">
					<mat-icon matIconPrefix svgIcon="mat:search"></mat-icon>
					<input matInput [formControl]="searchCtrl" placeholder="Ara&hellip;" type="text" />
				</mat-form-field>
				<mat-form-field subscriptSizing="dynamic" class="w-40">
					<mat-select [formControl]="programTypeCtrl" placeholder="Program Tipi">
						<mat-option [value]="null">{{ 'PAGE.DASHBOARD.SELECT' | translate }}</mat-option>
						@for (programType of programTypes; track programType) {
							<mat-option [value]="programType.value">
								{{ programType.label | translate }}
							</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<span class="flex-1"></span>
				<mat-form-field subscriptSizing="dynamic" class="w-24">
					<mat-select [formControl]="yearCtrl">
						@for (year of years; track year) {
							<mat-option [value]="year">
								{{ year }}
							</mat-option>
						}
					</mat-select>
				</mat-form-field>

				<button (click)="loadData()" mat-icon-button matTooltip="Yenile" type="button">
					<mat-icon svgIcon="mat:refresh"></mat-icon>
				</button>

				<button [matMenuTriggerFor]="columnFilterMenu" mat-icon-button matTooltip="Kolonlari seC" type="button">
					<mat-icon svgIcon="mat:filter_list"></mat-icon>
				</button>
			</div>
			<div class="grid grid-cols-1">
				<div class="card overflow-auto">
					@if (!loading && !dataSource.data.length) {
						<p class="p-4 text-center">{{ 'PAGES.NO_STUDENT' | translate }}</p>
					}
					@if (dataSource.data.length) {
						<table @stagger [dataSource]="dataSource" class="w-full student-table" mat-table>
							<ng-container [matColumnDef]="idColumn.property">
								<th *matHeaderCellDef mat-header-cell>{{ 'PAGE.DASHBOARD.TABLE.' + idColumn.label | translate }}</th>
								<td *matCellDef="let row" class="w-4" mat-cell>#{{ row[idColumn.property] }}</td>
							</ng-container>

							<ng-container [matColumnDef]="studentColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + studentColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									<span class="font-normal text-nowrap">{{ row.student.firstName }} {{ row.student.lastName }}</span>
									<br />
									<span class="font-bold"> {{ row.student.cityName }} </span>
								</td>
							</ng-container>

							<ng-container [matColumnDef]="contactColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + contactColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									<span class="font-bold">{{ row.student.email }}</span> <br />
									<span class="font-normal"> {{ row.student.phoneNumber }} </span>
								</td>
							</ng-container>

							<ng-container [matColumnDef]="programTypeColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + programTypeColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									<span class="rounded px-2 inline-block text-nowrap" [class]="'program-type-' + row.programType">
										{{ 'ENUM.PROGRAM_TYPE.' + row.programType | translate }}
									</span>
								</td>
							</ng-container>

							<ng-container [matColumnDef]="studentSourceColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + studentSourceColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									<div class="overflow-hidden flex-auto">
										<div class="font-medium">
											{{ 'ENUM.STUDENT_SOURCE.' + row.studentSource | translate }}
										</div>

										@if (
											row.studentSource == 1 ||
											row.studentSource == 6 ||
											row.studentSource == 4 ||
											row.studentSource == 8
										) {
											{{ row.referrerText }}
										}
										@if (row.studentSource == 2) {
											#{{ row.referrerId }} {{ row.referrerText }}
										}
										@if (row.studentSource == 3) {
											{{ row.referrerId | entity: 'StudyAbroadPoint' | async }}
										}
										@if (row.studentSource == 7) {
											{{ row.referrerId | entity: 'School' | async }}
										}
										@if (row.studentSource == 9) {
											{{ row.referrerId | entity: 'WebPortal' | async }}
										}
										@if (row.studentSource == 10) {
											{{ row.referrerId | entity: 'CorporateInquiry' | async }}
										}
										@if (row.studentSource == 11) {
											{{ row.referrerId | entity: 'StudyAbroadRepresentative' | async }}
										}
									</div>
								</td>
							</ng-container>

							<ng-container [matColumnDef]="enrollmentDateColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + enrollmentDateColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									{{ row.enrollmentDate | date: 'dd/MM/yyyy' }}
								</td>
							</ng-container>

							<ng-container [matColumnDef]="programStartDateColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + programStartDateColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									{{ row.programStartDate | date: 'dd/MM/yyyy' }}
								</td>
							</ng-container>

							<ng-container [matColumnDef]="statusColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + statusColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>
									<span class="rounded px-2 inline-block text-nowrap" [class]="'student-status-' + row.status">
										{{ 'ENUM.STUDENT_STATUS.' + row.status | translate }}
									</span>
								</td>
							</ng-container>

							<ng-container [matColumnDef]="programCountryColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + programCountryColumn.label | translate }}
								</th>
								<td *matCellDef="let row" class="w-4" mat-cell>
									@if (row.programCountryId) {
										<span class="font-bold">{{ row.programCountrId | entity: 'Country' | async }}</span> <br />
									}
									@if (row.programCityId) {
										<span class="font-normal"> {{ row.programCityId | entity: 'City' | async }} </span>
									}
								</td>
							</ng-container>

							<ng-container [matColumnDef]="schoolColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + schoolColumn.label | translate }}
								</th>
								<td *matCellDef="let row" class="w-4 whitespace-nowrap" mat-cell>
									@if (row.schoolName) {
										<span class="font-bold">{{ row.schoolName }}</span> <br />
									}
									@if (row.schoolBranchName) {
										<span class="font-normal"> {{ row.schoolBranchName }} </span>
									}
								</td>
							</ng-container>

							<ng-container [matColumnDef]="branchColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + branchColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell>{{ row.branchId | entity: 'Branch' | async }}</td>
							</ng-container>

							<ng-container [matColumnDef]="consultantColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + consultantColumn.label | translate }}
								</th>
								<td *matCellDef="let row" mat-cell class="text-nowrap">
									{{ row.consultantId | entity: 'User' | async }}
								</td>
							</ng-container>

							<ng-container [matColumnDef]="premiumColumn.property">
								<th *matHeaderCellDef mat-header-cell>
									{{ 'PAGE.DASHBOARD.TABLE.' + premiumColumn.label | translate }}
								</th>
								<td *matCellDef="let row" class="w-4" mat-cell>
									@if (row.premiumAmount) {
										@if (!row.paid) {
											<div
												class="text-amber-500 bg-amber-100 rounded p-2 flex items-center justify-center gap-2"
												matTooltip="Ödeme Bekleniyor"
											>
												{{ row.premiumAmount | currency: row.currencyId }}
												<mat-icon svgIcon="mat:timer"></mat-icon>
											</div>
										} @else {
											<div
												class="text-green-500 bg-green-100 rounded p-2 flex items-center justify-center gap-2"
												matTooltip="Ödeme Alındı"
											>
												{{ row.premiumAmount | currency: row.currencyId }}
												<mat-icon svgIcon="mat:check"></mat-icon>
											</div>
										}
									}
								</td>
							</ng-container>

							<tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
							<tr
								@fadeInUp
								mat-row
								class="hover:bg-hover transition duration-400 ease-out-swift cursor-pointer"
								*matRowDef="let row; columns: visibleColumns"
							></tr>
							<tr *matNoDataRow>
								<td [attr.colspan]="columns.length">
									<p class="p-4 text-center">{{ 'PAGES.NO_STUDENT' | translate }}</p>
								</td>
							</tr>
						</table>
					}
				</div>
			</div>
		}
	</vex-page-layout-content>
</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
	@for (column of columns; track column.property) {
		<button (click)="toggleColumnVisibility(column, $event)" class="mat-menu-item block">
			<mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
				{{ 'PAGE.DASHBOARD.TABLE.' + column.label | translate }}
			</mat-checkbox>
		</button>
	}
</mat-menu>
