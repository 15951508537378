export class Enums {
	static EducationLevel = {
		key: 'EDUCATION_LEVEL',
		values: {
			SecondarySchoolStudent: 1,
			SecondarySchoolGraduate: 2,
			HighSchoolStudent: 3,
			HighSchoolGraduate: 4,
			UniversityStudent: 5,
			UniversityGraduate: 6,
			MasterStudent: 7,
			MasterGraduate: 8,
			AssociateStudent: 9,
			AssociateGraduate: 10
		}
	};

	static ProgramType = {
		key: 'PROGRAM_TYPE',
		values: {
			LanguageSchool: 1,
			SummerSchool: 2,
			Internship: 3,
			University: 4,
			WorkAndTravel: 5,
			Diploma: 6,
			HighSchool: 7,
			Master: 8,
			SummerSchoolGroup: 9,
			Foundation: 10
		}
	};
}
