import { Component, Input, ViewChild } from '@angular/core';
import { ChartComponent, NgApexchartsModule } from 'ng-apexcharts';
import { ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexLegend } from 'ng-apexcharts';

@Component({
	selector: 'dashboard-chart',
	standalone: true,
	imports: [NgApexchartsModule],
	templateUrl: './dashboard-chart.component.html',
	styleUrl: './dashboard-chart.component.scss'
})
export class DashboardChartComponent {
	@ViewChild('chart') apexChart!: ChartComponent;

	@Input() series!: ApexNonAxisChartSeries;
	@Input() labels!: any;

	chart: ApexChart = {
		type: 'donut',
		height: 500,
		width: 500
	};
	responsive: ApexResponsive[] = [
		{
			breakpoint: 480,
			options: {
				chart: {
					width: 200,
					height: 200
				},
				legend: {
					position: 'bottom'
				}
			}
		}
	];
	legend: ApexLegend = {
		show: true,
		position: 'left',
		fontFamily: 'Inter var',
		fontSize: '14px'
	};

	constructor() {}
}
