import { Component, Input } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'dashboard-premium-table',
	standalone: true,
	imports: [MatProgressBarModule, TranslateModule],
	templateUrl: './dashboard-premium-table.component.html',
	styleUrl: './dashboard-premium-table.component.scss'
})
export class DashboardPremiumTableComponent {
	@Input() enrollmentDeserved!: number;
	@Input() partnerPackageType!: number;
  @Input() deservedPercentage!: number;

	showPricingTable = false;
}
